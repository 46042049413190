import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you are using React Router
import Navbar from './nav';
import Footer from './footer';
const OrderDetails = () => {
  const [token, setToken] = useState(''); // State for JWT token
  const [orders, setOrders] = useState([]); // State to hold the fetched order details
  const [errorMessage, setErrorMessage] = useState(''); // State for error messages
  const [loading, setLoading] = useState(false); // Loading state
 // Use history to redirect the user
  
  const navigate = useNavigate();
  useEffect(() => {
    // Check if token is present in localStorage
    const storedToken = localStorage.getItem('token');
    if (!storedToken) {
      // If token is not found, redirect to login page
      navigate('/login');
    } else {
      // If token is found, set the token state
      const storedToken = localStorage.getItem('token');
      setToken(storedToken);
      // Optionally fetch order details automatically when the token is available
      getOrderDetails(storedToken);
    }
  }, [navigate]); // Dependency array to ensure it runs once on component mount

  const getOrderDetails = async (token) => {
    setLoading(true);
    setErrorMessage('');
    setOrders([]);

    try {
        const storedToken = localStorage.getItem('token');
        setToken(storedToken);
        console.log(token)
      const response = await fetch('https://framefista.com/login1.php/orders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Attach JWT token in Authorization header
        },
      });

      const data = await response.json();

      if (response.ok) {
        setOrders(data.orders); // Set orders in state if response is successful
      } else {
        setErrorMessage(data.message); // Handle errors
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
      setErrorMessage('An error occurred while fetching your orders. Please try again later.');
      localStorage.removeItem('token');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{background:"rgb(255,253,245)"}}  class="pt-0 mt-0"   >
        <Navbar class=" pt-[0%] mt-0"/>
     

      {loading && <p>Loading...</p>}

      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

      {orders.length > 0 && (
        <div id="order-details" style={{ marginTop: '0px', padding: '15px', borderRadius: '5px' }}>
          <h2 class="mt-[10%] lg:mt-[5%]">Your Orders:</h2>
          {orders.map((order) => (
            <div key={order.id} className="order" style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
              <p><strong>Order ID:</strong> {order.order_id}</p>
              <p><strong>Email:</strong> {order.email}</p>
              <p><strong>Phone:</strong> {order.phone}</p>
              <p><strong>Date:</strong> {order.created_at}</p>
              <p><strong>Customer Name:</strong> {order.customer_name}</p>
              <p><strong>Product name:</strong> {order.product_name}</p>
              <p><strong>Quantity:</strong> {order.quantity}</p>
              <p><strong>City Name:</strong> {order.city_name}</p>
              <p><strong>Pin Code:</strong> {order.pin_code}</p>
              <p ><img src={order.product_image } width="250px"/></p>
              
            </div>
          ))}
        </div>
      )}

      {orders.length === 0 && !loading && !errorMessage && (
        <p>No orders found for this user.</p>
      )}
      <Footer />
    </div>
  );
};

export default OrderDetails;
