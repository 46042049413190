import React from 'react';
import './log.css';

function Footer() {
  return (
    <div className="w-full  text-black overflow-hidden" style={
      {background:"#f2efe3" , text:'#3b3933'}
    }>
      <div className="flex flex-col m-3 lg:flex lg:flex-row lg:m-3">
        <div className="w-[100%] lg:w-[70%]">
          <h3>About Us</h3>
          <p>
          Our expertise spans a wide array of innovative products designed to enhance and simplify modern living. From high-tech and personalized gifts tailored for trendsetters, to smart home automation solutions that bring convenience and intelligence to daily routines, we are dedicated to providing technology that enriches life. For pet owners, our pet technology offerings, including smart feeders and GPS trackers, ensure the health, safety, and happiness of furry companions. Additionally, our advanced robotics innovations, from cleaning robots to personal assistants, are reshaping the way people manage everyday tasks, making life easier and more efficient for everyone.
          </p>
        </div>
        
        <div className="m-0 lg:m-2">
          <h3 className="ml-3">Our Products</h3>
          <p> 🐾 Pet feeder</p>
          <p>  Home Automation</p>
          <p> Robotics</p>
          <p> innovative gifts</p>
        </div>
        
        <div className="m-0 lg:m-2">
          <h3 className="ml-3">Contact Us</h3>
          <p>Email: aman8804782801@gmail.com</p>
          <p>Phone: 9599886419</p>
          <p><a href="/logout">Logout</a></p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
