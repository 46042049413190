import { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const login = async (phoneNumber, password) => {
    try {
       
      const res = await axios.post('https://framefista.com/login1.php/auth/access/token', { phoneNumber, password });
      if(res.data.error){
        alert(res.data.error);
        return false
      }
      const token = res.data.access_token;
      const res1=res.data;
      
      console.log('Response data:', res.data);
      
      localStorage.setItem('token', token);
      if(res1.access_token){
      setUser({ phoneNumber });
      localStorage.setItem('phone',phoneNumber);
      return true;
      }
      else{
        return false;
      }
    } catch (error) {
      alert(error);
      console.error('Login failed:', error);
      return false;
    }
  };

  const register =async (phoneNumber, password,otp,email) => {
    try {
      console.log(otp);  // Log the OTP
  
      // Await the registration request
      const res = await axios.post('https://framefista.com/login1.php/auth/register', { phoneNumber, password, otp,email });
      
      // If successful, return true
      
     // console.log('Full response:', res);
      console.log( res.data.error);
      if(res.data.error){
        alert(res.data.error)
        return false;

      }else{
        return true;
      }
  

    } catch (error) {
      alert(error);
      console.error('Registration failed:', error);
      return false;  // Return false in case of an error
    }
    
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('loadTypes1');
    setUser(null);
  };

  const isAuthenticated = () => {
   const auth2= localStorage.getItem('token');
   console.log(String(auth2));
  if( auth2){
    return true;
  }
  else{
    return false;
  }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
  
    if (token) {
      // Fetch user data based on token if needed
      setUser({ phoneNumber: '8969774291' });
    }
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, register, logout, isAuthenticated, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
