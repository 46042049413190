import { useEffect } from 'react';
const loadEquipment = () => {
  return new Promise((resolve, reject) => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://framefista.com/login1.php/load', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();
        localStorage.setItem('loadTypes', JSON.stringify(data));
        console.log("Data loaded from API");
        resolve(); // Resolve once the data is successfully fetched
      } catch (error) {
        const savedLoadTypes = localStorage.getItem('loadTypes');
        if (savedLoadTypes) {
          const data = JSON.parse(savedLoadTypes);
          console.log("Data loaded from local storage");
          resolve(); // Resolve if data is loaded from local storage
        } else {
          alert("Internet error");
          reject("Unable to load data from API or local storage"); // Reject if nothing is available
        }
      }
    };

    // Call the fetch function
    fetchData();
  });
};

export default loadEquipment;
