import React, { useState, useEffect } from 'react';
import Navbar from './nav';
import Footer from './footer';

const Cart = () => {
  const [formattedString, setFormattedString] = useState("");
 const [loadTypesArray1,setloadTypesArray1]  = useState([]);

 // Get data from localStorage
const savedLoadTypes = localStorage.getItem('loadTypes');

// Parse the JSON string back to an array (if it exists)
const loadTypesArray = savedLoadTypes ? JSON.parse(savedLoadTypes) : [];








  useEffect(() => {
    // Retrieve data from localStorage on component mount
    const savedLoadTypes1 = localStorage.getItem('loadTypes1');
    const loadTypesArray1 = savedLoadTypes1 ? JSON.parse(savedLoadTypes1) : [];
    setloadTypesArray1(loadTypesArray1)
    // Build the formatted string
    const formatted = loadTypesArray1
      .map(item => `p${item.product}q${item.quantity}`)
      .join(" ");

    // Set the formatted string in state
    setFormattedString(formatted);
  }, []); // Only run on mount    <div>{formattedString || "No items in cart"}</div>
 

  // Initialize with the starting price
 let price1 = 0;

for (let i = 0; i < loadTypesArray1.length; i++) {
    const type = loadTypesArray1[i];
    
    // Ensure that type.product is a valid index in loadTypesArray
    const productIndex = type.product - 1;
    if (productIndex >= 0 && productIndex < loadTypesArray.length) {
        const price2 =loadTypesArray[productIndex].price;
        const price =  parseInt(price2.replace(/^Rs/, '').trim(), 10);
        if (!isNaN(price)) { // Check if price is a valid number
            price1 += price;
            console.log("Current Total Price:", price1);
        } else {
            console.log(`Invalid price at index ${productIndex}`);
        }
    } else {
        console.log(`Invalid product index: ${productIndex}`);
    }
}

console.log("Final Total Price:", price1);

  return (
    
      <div  style={{background:"rgb(255,253,245)"}} className='justify-content pb-3 ' >
        <Navbar />
      <h1>Your Cart</h1>
       
      
   
      

      <div className='mb-5 lg:text-2xl  text-xl flex flex-col mb-3'>
        {loadTypesArray1.map((item, index) => (

          <div className='m-5 lg:text-2xl    ' key={index}>
               <div className='text-base font-semibold flex flex-col' >
                 <img class="w-[200px] h-[200px] justify-left"src=   {loadTypesArray[item.product-1].image} />{/*loadTypesArray[item.product-1].name*/} 
               </div> 
               <p className=" m-0 font-normal text-2xl text-customGray ml-4"> {loadTypesArray[item.product-1].details}
              
               </p>

              <div className="flex flex-col p-1 mb-1  space-x-4 ml-4">

                <span className="text-sm font-medium text-gray-600">SKU: 12345</span>


                <div style={{ marginLeft: "0px" }}>
                  <span className="text-yellow-500">⭐</span>
                  <span className="text-yellow-500">⭐</span>
                  <span className="text-yellow-500">⭐</span>
                  <span className="text-yellow-500">⭐</span>
                  <span className="text-gray-300">⭐</span>
                  <span className="ml-1 text-sm text-gray-500">(25 Reviews)</span>
                </div>
              </div>




               <label class="p-0 ml-4   text-customGray" htmlFor="quantity">Quantity: {item.quantity}</label>




                <div class=" relative flex flex-col h-[25px] lg:h-[70px] ml-4 lg:mb-2  mb-[30px] w-[70%]" >

               <div class=" relative flex flex-row  m-0 font-normal w-[22%] h-[35px]"><div><h4 class=" p-0 h-[15px] text-brownish-gray-500 font-extrabold"></h4></div><div><h4 class="font-normal p-[0px] h-[15px] text-2xl" > {loadTypesArray[item.product-1].price}</h4></div> </div>

                </div>
          </div>
      

        ))}
       
      </div>




      






     <h4 class="font-normal text-lg font-bold  text-gray-600 p-[0px] h-[15px] text-2xl lg:ml-[2%] ml-[7%]" >Total= {price1}</h4>

      <a  className='mb-5 text-lg font-bold  text-gray-600 ml-[7%] mb-[50%]'   href="/request1">Checkout </a>
      <a  className='mb-5 text-lg font-bold  text-gray-600  ml-[7%] mb-[50%]'   href="/logout">Empty the cart </a>
      < div className="h-[300px]"></div>
      <Footer  className='mt-[500px] ' />
    </div>
  );
};

export default Cart;
