import React, { useState, useEffect, useId } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './log.css';
import Navbar from './nav';
import Footer from './footer';
import { useLocation } from 'react-router-dom';
import loadEquipment from './loadequipment';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}



// console.log(String(id));






// Get data from localStorage

function Equipment() {

 

  
  const savedLoadTypes = localStorage.getItem('loadTypes');

  // Parse the JSON string back to an array (if it exists)
  const loadTypesArray = savedLoadTypes ? JSON.parse(savedLoadTypes) : [];
 
  const randomNumber = Math.floor(Math.random() * 6) + 1;
  const randomNumber1 = randomNumber+3
  const loadItems2 = loadTypesArray.slice(randomNumber , randomNumber +3).map((type, i) => (
    <div key={i}  style={{ border: '0px solid black',background:'#f2efe380'}}class="ml-1 mr-1 mt-5   " >
      <a href={`/equipment?id=${encodeURIComponent(type.id)}`}>
        <img class="m-5 "  src={type.image} alt={type.name} width="85%" height="150px" />
      </a>
      <div className="w-[50%]">
      <a    href={`/equipment?id=${encodeURIComponent(type.id)}`}>
        <h5 className="text-base font-base  text-gray-600 mt-0 mb-0 ml-5 ">{type.name}</h5>
        <h5 className="text-xl font-bold text-gray-600 mt-0 ml-5 ">{type.price}</h5>
  
      </a>
      </div>
    </div>
  ));






  const [loadTypes1, setLoadTypes1] = useState(() => {
    const savedLoadTypes1 = localStorage.getItem('loadTypes1');

    return savedLoadTypes1 ? JSON.parse(savedLoadTypes1) : [];
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const placeholderImage = 'https://framefista.com/crane1.jpg';
  // Step 2: Update localStorage whenever `loadTypes` state changes
  useEffect(() => {
    localStorage.setItem('loadTypes1', JSON.stringify(loadTypes1));
  }, [loadTypes1]);

  // Step 3: Function to add a new element to the loadTypes array
  const addLoadType = (product, quantity) => {
    const newElement = { product, quantity };
    setLoadTypes1(prevLoadTypes => [...prevLoadTypes, newElement]);
  };


  const [quantity, setQuantity] = useState(1);
  // Find the item with id 2
  const query = useQuery();
  const id = query.get('id');
  console.log(id);
  const itemWithId2 = loadTypesArray.find(item => item.id == parseInt(id, 10));

  console.log(itemWithId2);

  const increaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };




  console.log(`ID: ${itemWithId2.id}`);
  console.log(`Name: ${itemWithId2.name}`);
  console.log(`Details: ${itemWithId2.details}`);
  console.log(`Load: ${itemWithId2.load}`);
  console.log(`Price: ${itemWithId2.price}`);
  console.log(`Power: ${itemWithId2.power}`);
  console.log(`Image: ${itemWithId2.image}`);
  console.log(`Image1: ${itemWithId2.image1}`);
  console.log(`Image2: ${itemWithId2.image2}`);
  console.log(`Image3: ${itemWithId2.image3}`);
  const name = itemWithId2.name
  const image = itemWithId2.image
  const image1 = itemWithId2.image1
  const image2 = itemWithId2.image2
  const image3 = itemWithId2.image3
  const load = itemWithId2.load
  const price = itemWithId2.price
  const details = itemWithId2.details
  const power = itemWithId2.power










  return (
    <div style={{ background: "rgb(255,253,245)" }}>
      
  
      <Navbar />

      <div class="overflow relative flex flex-col lg:flex-row h-[150%] pb-[30px]">

        <div class="mt-[20%] lg:mt-[7%] ml-5  m-5 ">
          <div class="flex flex-col lg:w-[100%] w-[100%]">


            <div class="relative flex flex-col h-[150%] ">
              <div class="flex flex-rows">


                <div>
                  <img
                    className="w-[240px] h-[250px] p-1   bg-white"
                    src={selectedImage || image || placeholderImage}
                    alt={name || 'Image'}
                  />
                </div>
                <div class="flex flex-col border-8 border-black ">
                  <div  > <img style={{ border: selectedImage === image ? '2px solid blue' : '1px solid black', borderRadius: '5px' }} class="w-[70px] h-[51px] mb-1 ml-1 p-1 bg-white " src={image} onClick={() => setSelectedImage(image)} alt={name} /> </div>
                  <div  > <img style={{ border: selectedImage === image1 ? '2px solid blue' : '1px solid black', borderRadius: '5px' }} class="w-[70px] h-[51px] mb-1 ml-1 p-1 bg-white " src={image1} onClick={() => setSelectedImage(image1)} alt={name} /> </div>
                  <div > <img style={{ border: selectedImage === image2 ? '2px solid blue' : '1px solid black', borderRadius: '5px' }} class="w-[70px] h-[51px] mb-1 ml-1 p-1  bg-white" src={image2} onClick={() => setSelectedImage(image2)} alt={name} /> </div>
                  <div ><img style={{ border: selectedImage === image3 ? '2px solid blue' : '1px solid black', borderRadius: '5px' }} class="w-[70px] h-[51px] mb-1 ml-1  p-1  bg-white" src={image3} onClick={() => setSelectedImage(image3)} alt={name} /></div></div>
              </div>

              <p className=" m-0 font-normal text-4xl text-customGray ml-4">{details}

              </p>

              <div className="flex flex-col p-1 mb-1  space-x-4 ml-4">

                <span className="text-sm font-medium text-gray-600">SKU: 12345</span>


                <div style={{ marginLeft: "0px" }}>
                  <span className="text-yellow-500">⭐</span>
                  <span className="text-yellow-500">⭐</span>
                  <span className="text-yellow-500">⭐</span>
                  <span className="text-yellow-500">⭐</span>
                  <span className="text-gray-300">⭐</span>
                  <span className="ml-1 text-sm text-gray-500">(25 Reviews)</span>
                </div>
              </div>



              <p className="font-normal  text-customGray m-0 text-xl  text-base ml-4 mb-5">{power}

              </p>

              <div class=" w-[50%] flex flex-row   rounded-lg  text-orange-600 border-4 border-orange-600 mb-0">

                <label class="p-0 ml-4   text-customGray" htmlFor="quantity">Quantity:</label>
                <button class="  text-xl border-0 " onClick={decreaseQuantity}>-</button>
                <input
                  class=" w-[30%] bg-white border-0 text-center"
                  type="number"
                  id="quantity"
                  name="quantity"
                  value={quantity}
                  min="1"
                  readOnly
                />
                <button class="  text-xl border-0 " onClick={increaseQuantity}>+</button>

              </div>
            </div>
            
            <div class=" relative flex flex-col h-[25px] lg:h-[70px] ml-4 lg:mb-2  mb-[30px] w-[70%]" >

              <div class=" relative flex flex-row  m-0 font-normal w-[22%] h-[35px]"><div><h4 class=" p-0 h-[15px] text-brownish-gray-500 font-extrabold"></h4></div><div><h4 class="font-normal p-[0px] h-[15px] text-2xl" >{price}</h4></div> </div>

            </div>








            <div class=" relative mt-5 flex flex-col  lg:w-[90%]  gap-2 ">



            {/*  <div class="flex">
                <h3>Load Types</h3>
                <ul>
                  {loadTypes1.map((item, index) => (
                    <li key={index}>
                      Product: {item.product}, Quantity: {item.quantity}
                      {loadTypesArray[item.product - 1].name}
                    </li>
                  ))}
                </ul>

              </div>  */}

              <div className='flex flex-row'>
                <button class="  mr-3 ml-3  m-0 rounded-lg pl-5 pr-5 p-3 bg-orange-600 text-white border-0 w-[95%] text-left text-base" onClick={() => addLoadType(id, quantity)}>Add to Cart</button>
                <div className='text-red-500 w-[5%]'>{loadTypes1.length}</div>
              </div>


              <div>
                <a href={`/request?id=${encodeURIComponent(id)}&quantity=${encodeURIComponent(quantity)}`}><p class="  mr-3 ml-3  m-0 rounded-lg pl-5 pr-5 p-3 bg-orange-600 text-white" >Order Now</p></a>

              </div>

              <div>
                <a href=''><p style={{ border: "2px solid orange" }} class=" rounded-lg pl-5 pr-3 mr-3 ml-3  p-3 m-0 text-orange-600 border-4 border-orange-600 " >Call Now
                  9599886419</p></a>

              </div>
              <div>


              </div>


            </div>
            
          </div>

          
        </div>
        <div   className=' w-[65%] lg:w-[20%] ml-[0%]  lg:ml-[40%] border-0 '>{/*loadItems2*/}</div>
      </div>
      <Footer />
    </div>
  );
}

export default Equipment;